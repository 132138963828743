import React from "react";
import { useParams } from "react-router";
import { QuizInfo } from "./QuizInfo";

export const UpdateQuiz = (props) => {
  const params = useParams();
  const handleSubmit = (event, title, desc, questions) => {
    event.preventDefault();
    const quiz = {
      title: title,
      desc: desc,
      status: "active",
      createdOn: parseInt(params.id),
      questions: questions,
    };
    props.handleQuizUpdate(quiz);
  };
  let currentQuiz = props.quizes.filter((quiz) => {
    return quiz.createdOn === parseInt(params.id);
  });
  // let quiz = {
  //   title: "Hi",
  //   desc: "Bye",
  //   createdOn: Date.now(),
  //   questions: [
  //     {
  //       id:Date.now(),
  //       questionTitle:"Who",
  //       option1:"A",
  //       option2:"B",
  //       option3:"C",
  //       option4:"D",
  //       correctAnswer:"B"
  //     }
  //   ],
  // };
  // console.log(currentQuiz);
  let current = currentQuiz[0];
  // console.log(currentQuiz.createdOn);
  return (
    // <QuizInfo quiz={quiz} handleSubmit={handleSubmit} />
    <div className="container justify-content-center ">
      <div className="container mt-3 bg-info">
        <span className="h3">Update Quiz</span>
      </div>
      {/* label create quiz  end */}
      <QuizInfo
        handleSubmit={handleSubmit}
        quiz={current}
        key={current.createdOn}
      />
    </div>
  );
};
